// Função para salvar dados no localStorage
export const saveToLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error("Error saving to localStorage:", error);
    }
};

// Função para recuperar dados do localStorage
export const getFromLocalStorage = (key) => {
    try {
        const item = localStorage.getItem(key);

        if (!item || item === "{}") {
            return null;
        }

        return JSON.parse(item);
    } catch (error) {
        console.error("Error getting data from localStorage:", error);
        return null;
    }
};

// Função para remover um item do localStorage
export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error("Error removing data from localStorage:", error);
    }
};