import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SheetsService from '../../services/sheets-service';
import { ButtonSecondary } from '../../components/ButtonSecondary';
import final1 from '../../assets/final1.svg'
import final2 from '../../assets/final2.svg'
import WhatsAppService from '../../services/whatsapp-service';
import './LogadoNovoINSS.css'

const sheetsService = new SheetsService();
const whatsService = new WhatsAppService();

function LogadoInss() {

    const parametros = useParams();

    const chamarNoWhatsApp = () => {
        const id = parametros.id;
        //SEM CPF POR ENQUANTO
        
        whatsService.abrirWhatsApp(WhatsAppService.RELATORIO_DIAGNOSTICO, id);
        sheetsService.enviarLogFrontPlanilha(id, '', SheetsService.CLICK_REQUEST_REPORT, '');

    };

    return (<>
        <div className="headerFinal"></div>
        <p className='textBolder'>Hora de receber seu resultado!</p>
        <p className='textComum'><b>Toque no botão abaixo</b> e envie a mensagem recomendada com seu protocolo para nosso WhatsApp oficial para receber:</p>
        <div>
            <img src={final1} className="imagem1" />
        </div>
        <img src={final2} className="imagem2" />
        <ButtonSecondary text="Receber meu diagnóstico!" action={chamarNoWhatsApp} />
        <div className="footerFinal"></div>
    </>)

}

export default LogadoInss;