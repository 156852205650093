import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ButtonPrimary } from './ButtonPrimary';
import { ButtonText } from './ButtonText';
import "./PopUpCustom.css"


export const modalsData = {
  CPF_INVALIDO: {
    alertText: 'CPF ou senha inválidos!',
    title: 'Por favor, confira se você digitou seu CPF e sua senha corretamente e tente mais uma vez.',
    content: '<b>Lembre-se:</b> A senha do gov.br e a mesma que você usa para acessar sua CNH digital, seu imposto de renda, sua carteira de vacinação e vários outros serviços!',
    textPrimaryButton: "Tentar novamente",
    textSecundaryButton: "Recuperar minha senha"
  },
  SENHA_REGEX: {
    alertText: 'Parece que você digitou uma senha inválida',
    title: 'Lembre-se que sua senha do gov deve ter <b>pelo menos 6 dígitos</b> e é a mesma que você usa para acessar sua CNH digital, sua carteira de vacinação e vários outros serviços!',
    content: 'Se você não lembra qual é sua senha, vamos te ajudar a criar uma nova!',
    textPrimaryButton: "Tentar novamente",
    textSecundaryButton: "Recuperar minha senha"
  }, SENHA_INVALIDA: {
    alertText: 'Precisa recuperar sua senha?',
    title: 'Se você já conferiu seu CPF e ainda não lembra sua senha, não se preocupe! <b>Preparamos um jeito simples de recuperar seu acesso!</b>',
    content: 'Toque no botão abaixo para  acessar nosso WhatsApp Oficial e receber <b>ajuda de nossos atendentes!</b>',
    textPrimaryButton: "Recuperar minha senha",
    textSecundaryButton: "Tentar Novamente"
  },
  DOIS_ERROS: {
    alertText: 'Vamos continuar seu atendimento no nosso WhatsApp oficial!',
    title: 'Parece que sua simulação não está dando certo por aqui, mas <b>nosso time de atendimento está pronto para te ajudar!</b>',
    content: 'Acesse nosso WhatsApp oficial de cálculos para terminar seu diagnóstico em poucos minutos.',
    textPrimaryButton: "Continuar no WhatsApp!",
    textSecundaryButton: "Tentar novamente"
  },
  SENHA_2FA: {
    alertText: 'Você está usando a autenticação de dois fatores do gov.br',
    title: 'Por isso, vamos terminar seu diagnóstico usando nosso outro canal de cálculos: O WhatsApp oficial da RevisaPrev!',
    content: '<b>Nosso time vai estar pronto para te ajudar</b> e você vai receber seu resultado em poucos minutos!',
    textPrimaryButton: "Continuar no WhatsApp!",
    textSecundaryButton: "Voltar"
  },
  CADASTRO_GOV: {
    alertText: 'Você precisa completar seu cadastro no gov.br',
    title: 'E nós vamos te ajudar a fazer isso! Acesse nosso WhatsApp oficial para receber o passo a passo de como regularizar seu cadastro receber seu resultado.',
    content: '<b>Nosso time já foi notificado e está pronto para te ajudar!</b>',
    textPrimaryButton: "Continuar no WhatsApp!",
    textSecundaryButton: "Voltar"
  },
  ERRO_DESCONHECIDO: {
    alertText: 'Não foi possível conectar com seu gov.br',
    title: 'Mas não se preocupe! Pode ser que o servidor do gov.br esteja apenas com alguma instabilidade.',
    content: 'Tente novamente o chame nosso time de atendimento diretamente no WhatsApp que vamos te ajudar!',
    textPrimaryButton: "Tentar Novamente",
    textSecundaryButton: "Falar com um atendente"
  },

  // Adicione mais modais conforme necessário
};//modalKey 

const PopUpCustom = ({ modalInfo, isOpen, onClose, actionPrimaryButton, actionSecundaryButton }) => {

  return (
    <Popup open={isOpen} onClose={onClose}>
      <div className='popupCust'>
        <p className='alerta'>{modalInfo.alertText}</p>
        <p className='sobalerta' dangerouslySetInnerHTML={{ __html: modalInfo.title }} />
        <p className='sobalerta' dangerouslySetInnerHTML={{ __html: modalInfo.content }} />
        <div className='botoes'>
          <ButtonPrimary text={modalInfo.textPrimaryButton} action={actionPrimaryButton} />
          {modalInfo.textSecundaryButton && (
            <ButtonText text={modalInfo.textSecundaryButton} action={actionSecundaryButton} />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default PopUpCustom;

