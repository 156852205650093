
import styles from './ButtonSecondary.css';
import WhatsLogo from '../assets/whatslogo.svg'


export function ButtonSecondary(props) {

    return (<button className="buttonAzulClaro" onClick={props.action}>
        {props.text}
        <img src={WhatsLogo} alt="WhatsApp Logo" className="whatsLogo" />
    </button>);
}