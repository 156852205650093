



export const chamarNoWhatsApp = (celular, texto) => {

    let isMobile =  false; ///Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    let url = isMobile ? "whatsapp://send?phone=" + celular + "&text=" + encodeURIComponent(texto) :
        "https://api.whatsapp.com/send?phone=" + celular + "&text=" + encodeURIComponent(texto); // Encode do texto para URL

    if (isMobile) {
        window.location.href = url;
    } else {
        window.open(url, '_blank');
    }

};