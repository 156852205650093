import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './LoadingLottie.css';

function LoadingLottie({ animationData, loop = true, autoplay = true }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
    });   
    return () => {
      animation.destroy();
    };
  }, []); 
  return <div className="loading-lottie" ref={containerRef} />;
}

export default LoadingLottie;
