import { FAQ } from '../../components/Faq'; 
import './pageTest.css'

function TestPage() {
  return (
    <div>
      <div className="headerTest"></div>
      <h1 className='textAviso'>Bem vindo a página Test da RevisaPrev!</h1>      
      <FAQ /> {}
    </div>
  );
}

export default TestPage;