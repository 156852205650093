import { chamarNoWhatsApp } from './whatsapp-envio';


export default class WhatsAppService {

    static RELATORIO_DIAGNOSTICO = 'Olá, fiz meu diagnóstico de aposentadoria pelo aplicativo RevisaPrev com protocolo nº: #ID# e vim solicitar meu resultado!';
    static RECUPERACAO_SENHA = "Olá, iniciei meu diagnóstico de aposentadoria pelo Simulador da RevisaPrev e preciso de ajuda para recuperar minha senha do GOVbr";
    static ERRO_NAO_TRATADO = "Olá, iniciei meu diagnóstico de aposentadoria pelo Simulador da RevisaPrev com protocolo nº: #ID# e preciso de ajuda para finalizar.";
    static ERRO_SELO_INSS = "Olá, iniciei meu diagnóstico de aposentadoria pelo Simulador da RevisaPrev com protocolo nº: #ID# e preciso de ajuda para completar meu cadastro do INSS.";
    static ERRO_2FA = "Olá, iniciei meu diagnóstico de aposentadoria pelo Simulador da RevisaPrev com protocolo nº: #ID# e tenho a autenticação de dois fatores habilitada. Como posso receber meu diagnóstico?";

    constructor() {
        this.celularPlanejamento = process.env.REACT_APP_CELULAR_PLANEJAMENTO;
    }

    abrirWhatsApp(texto, id) {
        
        if (id != null && id.length > 0) {
            texto = texto.replace("#ID#", id);
        } else {
            texto = texto.replace("com protocolo nº: #ID#", "");
        }

        chamarNoWhatsApp(this.celularPlanejamento, texto);
    }

}


