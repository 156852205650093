
const BLACKLIST = [
  "00000000000",
  "11111111111",
  "22222222222",
  "33333333333",
  "44444444444",
  "55555555555",
  "66666666666",
  "77777777777",
  "88888888888",
  "99999999999",
  "12345678909"
];

const STRIP_REGEX = /[^\d]/g;

function verifierDigit(cpf) {
  const numbers = cpf.split("").map(number => parseInt(number, 10));

  const modulus = numbers.length + 1;

  const multiplied = numbers.map((number, index) => number * (modulus - index));

  const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;

  return mod < 2 ? 0 : 11 - mod;
}

/*
function format(cpf) {
  const regex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;

  return strip(cpf).replace(regex, "$1.$2.$3-$4");
}
*/

function strip(cpf) {
  return cpf.replace(STRIP_REGEX, "");
}

function isCpfValid(cpf, stripBeforeValidation = true) {
  if (stripBeforeValidation) {
    cpf = strip(cpf);
  }

  if (!cpf || cpf.length !== 11 || BLACKLIST.includes(cpf)) {
    return false;
  }

  const numbers = cpf.substring(0, 9) + verifierDigit(cpf.substring(0, 9)) + verifierDigit(cpf.substring(0, 10));

  return numbers.slice(-2) === cpf.slice(-2);
}
 
export default isCpfValid 
 
