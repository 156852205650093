import React, { useState } from 'react';
import './Faq.css';
import setaBaixo from "../assets/setabaixo.svg";

export function FAQ() {
  const faqs = [
    {
      pergunta: 'Murilinxd é god ou num',
      resposta: 'nossa murilinxd ta mostrando ser god Resposta -Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean at luctus justo, sit amet placerat dui. Nam id ligula in neque tincidunt facilisis. Nam at lacus arcu. Integer sollicitudin commodo blandit. In cursus et sapien ut placerat. Duis lectus lectus, venenatis et posuere vitae, bibendum et dui. Donec quam orci, condimentum vitae eros in, mattis laoreet tellus. ',
    },
    {
      pergunta: 'RevisaPrev op ou nem',
      resposta: 'RevisaPrev é op',
    },
  ];

  const [perguntaAberta, setperguntaAberta] = useState(null);

  const mostrarResposta = (index) => {
    if (perguntaAberta === index) {
      setperguntaAberta(null);
    } else {
      setperguntaAberta(index);
    }
  };

  return (
    <div>
      {faqs.map((faq, index) => (
        <div
          className={`faq-container ${
            perguntaAberta === index ? 'faq-aberto' : 'faq-fechado'
          }`}
          key={index}
        >
          <strong>{faq.pergunta}</strong>
          <button
            className="botao-seta"
            onClick={() => mostrarResposta(index)}
          >
            <img src={setaBaixo} alt="Seta para baixo" />
          </button>
          {perguntaAberta === index && <p>{faq.resposta}</p>}
        </div>
      ))}
    </div>
  );
}
