import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import LoginInss from "./pages/login-inss/LoginInss";
import LogadoInss from "./pages/login-inss-logado/LogadoInss";
import LogadoNovoInss from './pages/login-inss-logado/LogadoNovoINSS';
import Loading from "./pages/login-inss-loading/LoginLoading"; 
import Test from "./pages/pagetest/pageTest"; 





export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginInss />}> 
        </Route>
        <Route path="/concluido/:id/" element={<LogadoNovoInss />} />
        <Route path="/loading" element={<Loading />} /> 
        <Route path="/Novo" element={<LogadoInss />} />
        <Route path="/RevisaPrevabovetheskies" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}

