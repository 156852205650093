import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SheetsService from '../../services/sheets-service';
import { ButtonSecondary } from '../../components/ButtonSecondary';
import './LogadoInss.css'
import verificado from '../../assets/verificado.svg'
import WhatsAppService from '../../services/whatsapp-service';

const sheetsService = new SheetsService();
const whatsService = new WhatsAppService();

function LogadoInss() {

    const parametros = useParams();



    const chamarNoWhatsApp = () => {
        const id = parametros.id;

        whatsService.abrirWhatsApp(WhatsAppService.RELATORIO_DIAGNOSTICO, id); 

    };

    return (<>
        <div className="headerResult"></div>
        <img src={verificado} className="verificado" />
        <p className='text1'>Seu resultado está pronto!</p>
        <p className='text2'>Para receber seu diagnóstico personalizado de aposentadoria, <b>toque no botão</b> abaixo e envie a mensagem para o WhatsApp Oficial da RevisaPrev!</p>
        <ButtonSecondary text="Receber meu diagnóstico!" action={chamarNoWhatsApp} />
        <div className="footerResult"></div>
    </>)

}

export default LogadoInss;