
import axios from 'axios';
import StorageServices from './storage-service'

// Configuração do Axios com timeout de 2 minuto
const axiosInstance = axios.create({
    timeout: 120000 // Tempo limite de 2 minuto
});

class PwaServices {



    constructor() {
        this.linkApi = process.env.REACT_APP_LINK_API || 'https://dev-api.revisaprev.com.br/revisaprev/v1/api/pwa/registrar';
        this.linkTokens = process.env.REACT_APP_LINK_TOKENS || 'http://localhost:3000/run';
        this.storageService = new StorageServices();
    }

    async calcular(authToken, miToken) {
        console.log('Link Calculo: ' + this.linkApi);
        console.log('Calcular: ' + authToken);
        const fluxoPlanejamento = await this.storageService.isFluxoPlanejamento();
        let retornoCalculo = null;
        try {
            const idAndroid = await this.storageService.getDeviceId();
            const idForm = await this.storageService.getFormId();
            const masculino = true;
            const request = {
                cliente: "2",
                beneficio: "",
                authToken: authToken.replace("\"", ""),
                miToken: miToken.replace("\"", ""),
                androidId: idAndroid,
                masculino,
                idForm,
                fluxoPlanejamento
            };

            const auth = '1FdznBr^d4f$c$ZRNUCE07cZ6wzJRvAKkbSlTLnzp@wsISGEfw';
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': auth
            };

            const response = await axiosInstance.post(this.linkApi, request, { headers });

            if (response.status === 200) {
                const jsonData = response.data;
                retornoCalculo = {
                    nome: jsonData.nome,
                    id: jsonData.id,
                    resultado: jsonData.resultado,
                    aposentado: jsonData.aposentado,
                    visualizado: fluxoPlanejamento === jsonData.aposentado
                };
            }
        } catch (error) {
            console.error(error);
        }

        return retornoCalculo;
    }

    async pegarTokens(cpf, senha) {

        //isso é pra simular algum erro da API
        /*
        const jsonData = { 
            status: {
                descricao: '',
                codigo: 402
            }, 
        }
      

        return jsonData;
        */
         

        const idAndroid = this.storageService.getDeviceId();

        const request = { cpf, senha, androidId: idAndroid };
        const headers = {
            'Content-Type': 'application/json',
        };

        const response = await axiosInstance.post(this.linkTokens, request, { headers });


        this.jsonData = response.data;

        return this.jsonData;
    }



}

export default PwaServices;
