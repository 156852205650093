import React, { useState, useEffect, useRef } from 'react';
import { ButtonPrimary } from '../../components/ButtonPrimary';
import Logo from '../../assets/logoInssGov.svg';
import './logininss.css';
import PwaServices from '../../services/pwa-services.jsx';
import isCpfValid from '../../services/cpf-validate';
import SheetsService from '../../services/sheets-service';
import Loading from '../login-inss-loading/LoginLoading';
import { useNavigate } from 'react-router-dom';
import PopupCustom, { modalsData } from '../../components/PopUpCustom';
import olhoAberto from '../../assets/olhoaberto.svg';
import olhoFechado from '../../assets/olhofechado.svg';
import WhatsAppService from '../../services/whatsapp-service';
import StorageServices from '../../services/storage-service'

const pwaServices = new PwaServices();
const sheetsService = new SheetsService();
const whatsService = new WhatsAppService();
const storageService = new StorageServices();

let tentativasLoginSenhaInvalida = 0;
let erroRequestApiCalculo = 0;

function LoginINSS() {

    const isMounted = useRef(true);

    useEffect(() => {
        if (isMounted.current) {
            sheetsService.enviarLogFrontPlanilha('', '', SheetsService.PAGE_VIEW, '');
            isMounted.current = false;
        }
    }, []);

    const closeAlert = () => {
        setIsAlertOpen(false);
    };



    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [currentModalInfo, setCurrentModalInfo] = useState(modalsData.CPF_INVALIDO);
    const [currentActionPrimaryButton, setCurrentActionPrimaryButton] = useState(() => closeAlert);
    const [currentActionSecundaryButton, setCurrentActionSecundaryButton] = useState(() => closeAlert);


    const [cpf, setCpf] = useState('');
    const [senha, setSenha] = useState('');
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();



    const showSenha = () => {
        setMostrarSenha(!mostrarSenha);
    };

    function formatarCPF(cpf) {
        cpf = cpf.replace(/\D/g, '');
        cpf = cpf.substring(0, 11);
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        return cpf;
    }

    function handleChange(event) {
        ativaError('.campo input', '.campo label', '.campo .error-cpf', false);
        setCpf(formatarCPF(event.target.value));
    }

    function ativaError(qualInput, qualLabel, mostrarErro, situacao) {
        const input = document.querySelector(qualInput);
        const label = document.querySelector(qualLabel);
        const error = document.querySelector(mostrarErro);

        if (input === null || label === null || error === null) {
            return;
        }

        if (situacao !== false) {
            input.classList.add('error');
            label.classList.add('error');
            error.style.display = 'block';
        } else {
            input.classList.remove('error');
            label.classList.remove('error');
            error.style.display = 'none';
        }
    }

    const login = async () => {

        let startTime;
        let endTime;
        try {
            if (preValidacaoLogin()) {
                setLoading(true);
                startTime = performance.now();
                let retornoTokens = await pwaServices.pegarTokens(cpf, senha);
                endTime = performance.now();
                let totalTimePwa = (endTime - startTime) / 1000;
                totalTimePwa = totalTimePwa.toFixed(2);
                if (validacaoRetornoTokens(retornoTokens, totalTimePwa)) {

                    let retornoCalculo = await pwaServices.calcular(retornoTokens.ifs_auth, retornoTokens.miToken);
                    if (retornoCalculo != null) {
                        //nesse momento se espera que deu tudo certo e vá para a página final que deve receber o id... 
                        navigate('/concluido/' + retornoCalculo.id);
                        sheetsService.enviarLogFrontPlanilha('', '', SheetsService.PAGE_RESULT, ''); //enviando que foi pra página de resultado
                        sheetsService.enviarLogFrontPlanilha(retornoCalculo.id, cpf, SheetsService.CALC_SUCCESS, '');
                    } else {
                        //aqui é um erro na requisição da API de calculo, provavel um POPUP
                        openModal(modalsData.ERRO_DESCONHECIDO, closeAlert, ajudaErroNaoTratato);
                        sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_REQUEST_CALC, '');
                    }
                }

            }
        } catch (error) {
            const endTime = performance.now();
            let timeTaken = (endTime - startTime) / 1000;
            timeTaken = timeTaken.toFixed(2);
            console.log('Error: ' + error.toString() + ', count: ' + erroRequestApiCalculo);
            if (erroRequestApiCalculo === 0) { //1 retry 
                erroRequestApiCalculo++;
                sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_UNKNOW_RETRY, error.toString());
                await login();
            } else {
                erroRequestApiCalculo++;
                openModal(modalsData.ERRO_DESCONHECIDO, closeAlert, ajudaErroNaoTratato);
                sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_UNKNOW, error.toString() + " Total time: " + timeTaken);

            }
        }
        setLoading(false);

    }
    //aqui falta lançar os popups para cada situação
    const validacaoRetornoTokens = (retornoTokens, totalTimePwa) => {
        if (retornoTokens.status.codigo === 200) {
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.PWA_SUCCESS, 'Time total: ' + totalTimePwa + " sec");
            return true;
        } else if (retornoTokens.status.codigo === 402) {
            tentativasLoginSenhaInvalida++;//só contabilizando quando for erro de senha inválida
            if (tentativasLoginSenhaInvalida === 1) {
                openModal(modalsData.CPF_INVALIDO, closeAlert, recuperarSenha);
                sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.PASSWORD_1TENTATIVA, 'Total time: ' + totalTimePwa + " sec");
            } else {
                openModal(modalsData.SENHA_INVALIDA, recuperarSenha, closeAlert);
                sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.PASSWORD_2TENTATIVA, 'Total time: ' + totalTimePwa + " sec");
            }
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.PASSWORD_INVALID, 'Total time:' + totalTimePwa + " sec");
            setCpf(''); //zerando senha quando está inválida
        } else if (retornoTokens.status.codigo === 403) {
            openModal(modalsData.ERRO_DESCONHECIDO, closeAlert, ajudaErroNaoTratato); //@Murilo verifica se tem esse erro de cadsatro bloqueado no figma
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_REGISTER_BLOCKED, 'Total time: ' + totalTimePwa + " sec");
        } else if (retornoTokens.status.codigo === 404) {
            openModal(modalsData.ERRO_DESCONHECIDO, closeAlert, ajudaErroNaoTratato);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_CAPTCHA, 'Total time: ' + totalTimePwa + " sec");
        } else if (retornoTokens.status.codigo === 406) {
            openModal(modalsData.SENHA_2FA, ajuda2FA, closeAlert); //@Murilo verifica se tem esse erro de 2FA Mutiple request  no figma
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_2FA_REQUESTS, 'Total time: ' + totalTimePwa + " sec");
        } else if (retornoTokens.status.codigo === 505) {
            openModal(modalsData.SENHA_2FA, ajuda2FA, closeAlert); //@Murilo verifica se tem esse erro de 2FA  no figma
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_2FA, 'Total time:' + totalTimePwa + " sec");
        } else if (retornoTokens.status.codigo === 506) {
            openModal(modalsData.CADASTRO_GOV, ajudaSeloInss, closeAlert);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_SELO_INSS, 'Total time: ' + totalTimePwa + " sec");
        } else if (retornoTokens.status.codigo === 507) {
            openModal(modalsData.ERRO_DESCONHECIDO, closeAlert, ajudaErroNaoTratato);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_CAPTCHA_UNSOLVED, 'Total time: ' + totalTimePwa + " sec");
        } else {
            openModal(modalsData.ERRO_DESCONHECIDO, closeAlert, ajudaErroNaoTratato);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.ERROR_GENERIC, retornoTokens.status.descricao + " Total time: " + totalTimePwa + " sec");
        }
        return false;
    }

    const preValidacaoLogin = () => {

        if (cpf.length <= 10) {
            ativaError('.campo input', '.campo label', '.campo .error-cpf', true);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.CPF_EMPTY, '');
            return false;
        } else {
            ativaError('.campo input', '.campo label', '.campo .error-cpf', false);
        }
        if (!isCpfValid(cpf)) {
            ativaError('.campo input', '.campo label', '.campo .error-cpf', true);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.CPF_INVALID, '');
            return false;
        } else {
            ativaError('.campo input', '.campo label', '.campo .error-cpf', false);
        }


        if (senha.length <= 5) {
            ativaError('.campo input[value="' + senha + '"]', '.campo label[for="senha"]', '.campo .error-senha', true);
            openModal(modalsData.SENHA_REGEX, closeAlert, recuperarSenha);
            sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.PASSWORD_REGEX, '');
            return false;
        } else {
            ativaError('.campo input[value="' + senha + '"]', '.campo label[for="senha"]', '.campo .error-senha', false);
        }

        return true;
    }

    const openModal = (modalInfo, actionPrimaryButton, actionSecundaryButton) => {
        setCurrentModalInfo(modalInfo);
        setCurrentActionPrimaryButton(() => actionPrimaryButton);
        setCurrentActionSecundaryButton(() => actionSecundaryButton);

        setIsAlertOpen(true);
    };

    const recuperarSenha = () => {
        whatsService.abrirWhatsApp(WhatsAppService.RECUPERACAO_SENHA);
    }

    const ajudaErroNaoTratato = async () => {
        let idForm = await storageService.getFormId();
        whatsService.abrirWhatsApp(WhatsAppService.ERRO_NAO_TRATADO, idForm);
    }

    const ajuda2FA = async () => {
        let idForm = await storageService.getFormId();
        whatsService.abrirWhatsApp(WhatsAppService.ERRO_2FA, idForm);
    }

    const ajudaSeloInss = async () => {
        let idForm = await storageService.getFormId();
        whatsService.abrirWhatsApp(WhatsAppService.ERRO_2FA, idForm);
    }


    if (loading) { //aqui vamos botar o componente do loading
        sheetsService.enviarLogFrontPlanilha('', cpf, SheetsService.PAGE_LOADING, '');
        return (
            <Loading />
        );
    }




    return (
        <>

            <div className="header1"></div>
            <p className="sobretexto">Faça sua conexão segura com</p>
            <img src={Logo} className="logo" />
            <div className="campo">
                <label for="cpf">Seu CPF</label>
                <input
                    type="text"
                    placeholder='000.000.000-00'
                    value={cpf}
                    inputMode="numeric"
                    onChange={handleChange}
                />
                <span className="error-cpf">CPF inválido</span>
            </div>
            <div className="campo">
                <label for="senha">Sua senha do gov.br</label>
                <input
                    type={mostrarSenha ? 'text' : 'password'}
                    placeholder='Insira sua senha'
                    value={senha}
                    onChange={(event) => {
                        setSenha(event.target.value);
                        ativaError('.campo input[value="' + senha + '"]', '.campo label[for="senha"]', '.campo .error-senha', false);
                    }}
                />
                <span className="error-senha">Insira sua senha</span>
                <div className="mostrarsenha" onClick={showSenha}><img src={mostrarSenha ? olhoAberto : olhoFechado} alt="" /></div>
            </div>
            <a className='recuperarSenha' onClick={recuperarSenha} target="_blank">
                <p className='recuperarSenha'> Esqueceu sua senha? Toque aqui e vamos te ajudar a recuperar!</p>
            </a>
            <ButtonPrimary text="Acessar e simular com o gov.br" action={login} />

            <p className='recuperarSenha2'>
                <a href='https://revisaprev.com.br/termos-de-uso-politicas-e-privacidade-geral/'>
                    Ao continuar, você concorda com os <u><b>Termos de Uso e Política de Privacidade</b></u> da RevisaPrev.
                </a>
            </p>
            
            <div className="footer1"></div>


            <PopupCustom
                modalInfo={currentModalInfo}
                isOpen={isAlertOpen}
                onClose={closeAlert}
                actionPrimaryButton={currentActionPrimaryButton}
                actionSecundaryButton={currentActionSecundaryButton}
            >
            </PopupCustom>
        </>


    );
}

export default LoginINSS;