import http from 'axios';
import StorageServices from './storage-service'



class SheetsService {

    static PAGE_VIEW = "PAGE_VIEW";
    static CPF_INVALID = 'ACTION_CPF_INVALID';
    static CPF_EMPTY = 'ACTION_CPF_EMPTY';
    static PASSWORD_EMPTY = "ACTION_PASSWORD_EMPTY"
    static PASSWORD_REGEX = "ACTION_PASSWORD_REGEX_INVALID";
    static PASSWORD_1TENTATIVA = "PASSWORD_1TENTATIVA";
    static PASSWORD_2TENTATIVA = "PASSWORD_2TENTATIVA";
    static PAGE_LOADING = "PAGE_LOADING";
    static PWA_SUCCESS = "ACTION_PWA_SUCCESS"
    static CALC_SUCCESS = "ACTION_API_IDCALC_SUCCESS";
    static ERROR_REQUEST_CALC = "ACTION_ERROR_CALC_REQUEST";
    static PASSWORD_INVALID = "ACTION_PASSWORD_INSS_INVALID";
    static ERROR_CAPTCHA = "ACTION_ERROR_CAPTCHA";
    static ERROR_2FA_REQUESTS = "ACTION_ERROR_2FA_MULTIPLE_REQUESTS";
    static ERROR_2FA = "ACTION_ERROR_2FA";
    static ERROR_SELO_INSS = "ACTION_ERROR_SELOINSS";
    static ERROR_GENERIC = "ACTION_ERROR_PWA_GENERIC"
    static ERROR_REGISTER_BLOCKED = "ERROR_REGISTER_BLOCKED";
    static ERROR_UNKNOW = "ACTION_ERROR_API";
    static ERROR_UNKNOW_RETRY = "ACTION_ERROR_API_RETRY";
    static PAGE_RESULT = "PAGE_RESULT";
    static CLICK_REQUEST_REPORT = "CLICK_REQUEST_REPORT";
    static ERROR_CAPTCHA_UNSOLVED = "ERROR_CAPTCHA_UNSOLVED";

    constructor() {
        this.linkPlanilhaFront = process.env.REACT_APP_LINK_PLANILHA_FRONT || 'https://dev-api.revisaprev.com.br/revisaprev/v1/api/gsheets/pwa-logs-front';
        this.storageService = new StorageServices();
    }


    async enviarLogFrontPlanilha(id, cpf, acao, log) {

        const idForm = await this.storageService.getFormId();
        const deviceId = await this.storageService.getDeviceId();
        const planejamento = await this.storageService.isFluxoPlanejamento();

        let fluxo = planejamento ? 'CONCESSAO' : 'REVISAO';

        var jsonData = {
            'deviceId': deviceId,
            'idForm': idForm,
            'acao': acao,
            'cpf': cpf,
            'id': id,
            'fluxo': fluxo,
            'log': log
        };


        const headers = {
            'Content-Type': 'application/json',
            'Authorization': '1FdznBr^d4f$c$ZRNUCE07cZ6wzJRvAKkbSlTLnzp@wsISGEfw'
        };


        if (process.env.REACT_APP_PROD === 'true') {
            try {
                const response = await http.post(this.linkPlanilhaFront, JSON.stringify(jsonData, null, 2), { headers });
                if (response.status !== 200) {
                    throw new Error('Falha ao enviar log para planilha');
                }
            } catch (error) {
                console.error(error);
            }
        }


    }
}

export default SheetsService;