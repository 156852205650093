import React, { useState, useEffect } from 'react';

function PreparandoDiagnostico() {
  const mensagens = [
    "Estamos preparando seu diagnóstico de aposentadoria!",
    "<b>Você sabia?</b><br/><br/> 3 em cada 4 aposentadorias são negadas pelo INSS! Mas por que isso acontece?",
    "Porque o INSS desconsidera qualquer vínculo que tenha pendências. Até as que podem ser resolvidas de forma simples!",
    "Por isso, até nas aposentadorias que são concedidas, 6 em cada 10 estão abaixo do valor!",
    "<b>Mas não se preocupe!</b><br/> <br/>Vamos garantir sua aposentadoria com o maior benefício possível!",
    "O Gov.br está demorando para responder, mas ainda estamos trabalhando!"
  ];

  const [indiceMensagem, setIndiceMensagem] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndiceMensagem((indice) => (indice + 1) % mensagens.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: mensagens[indiceMensagem] }} />
    </>
  );
}

export default PreparandoDiagnostico;
