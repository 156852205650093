import React, { useEffect, useState } from 'react';
import LoadingLottie from '../../components/LoadingLottie';
import MensagemLoading from '../../components/MensagemLoading';
import './LoginLoading.css';
import animationLoading from '../../assets/loadingLottie.json';

function Loading() {
  const [loadingProgress, setLoadingProgress] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress(progressoAntigo => {
        if (progressoAntigo < 40) {
          return progressoAntigo + 1.5;
        }
        else if (progressoAntigo < 90) {
          return progressoAntigo + 0.75;
        }
        else if (progressoAntigo < 98.9) {
          return progressoAntigo + 0.10; 
        }
        else {
          clearInterval(interval); 
          return progressoAntigo;
        }
      });
    }, 300); 
    return () => {
      clearInterval(interval); 
    };
  }, []);

  
  return (
    <>
      <div className="loading-bar-container">     
      <div className="loading-bar" style={{ width: `${loadingProgress}%` }}></div>
      </div>
      <p className='alertaLoading'>Não feche essa janela!</p>
      <p className='sobalertaLoading'>Estamos nos conectando com o GOV.br. Isso pode levar até 1 minuto.</p>
      <LoadingLottie animationData={animationLoading} />
      <div className="mensagens"><MensagemLoading /></div>


      <div className="footerLoad"></div>
    </>
  );
}

export default Loading;
